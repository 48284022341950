import React from "react";
import Chart from "react-apexcharts";
import { mockBarData as data } from "../data/mockData";

const BarChart = () => {
  const greenData = data.map((item) => (item.TarbimineColor === "hsl(120, 70%, 50%)" ? item.Tarbimine : null));
  const orangeData = data.map((item) => (item.TarbimineColor === "hsl(39, 100%, 50%)" ? item.Tarbimine : null));
  const redData = data.map((item) => (item.TarbimineColor === "hsl(0, 100%, 50%)" ? item.Tarbimine : null));

  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      foreColor: '#ffffff'
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top",
        },
      },
      


    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#ffffff"],
      },
    },

    tooltip: {
      theme: 'dark', // this will set the tooltip background to dark. If you want it to be light, use 'light'.
      style: {
        colors: ['#000'] // color of the text
      }
      },
    series: [
      {
        name: "Odav",
        data: greenData,
      },
      {
        name: "Keskmine",
        data: orangeData,
      },
      {
        name: "Kallis",
        data: redData,
      },
    ],
    colors: ["hsl(120, 70%, 50%)", "hsl(39, 100%, 50%)", "hsl(0, 100%, 50%)"],
    xaxis: {
      categories: data.map((item) => item.Month),
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
     
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    title: {
      text: "Tänase päeva elektrihinnad",
      floating: true,
      offsetY: 0,
      align: "center",
      style: {
        color: "#fff",
      },
    },
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={chartOptions}
            series={chartOptions.series}
            type="bar"
            width="1000"
          />
        </div>
      </div>
    </div>
  );
};

export default BarChart;
