import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";


const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

    // Create state variables for your data
    const [hetkeTarbimine, setHetkeTarbimine] = useState(null);
    const [hetkeTarbimine2, setHetkeTarbimine2] = useState(null);
    const [sagedus, setSagedus] = useState(null);
    const [pinge, setPinge] = useState(null);
    const [koguTarbimine, setKoguTarbimine] = useState(null);
    const [price, setPrice] = useState(null);
    const [arvestiData, setArvestiData] = useState(null);



    const sparklineOptions = {
      chart: {
          type: 'line',
          width: '100%',
          height: '70%',
          sparkline: {
              enabled: true
          }
      },
      stroke: {
          curve: 'smooth'
      },
      fill: {
          opacity: 0.3,
      },
      series: [{
          data: [23, 43, 35, 44, 45, 45, 56, 56, 39]  // This is sample data
      }],
      xaxis: {
          type: 'numeric'
      }
  };


    useEffect(() => {
      const fetchData = () => {
        
        fetch('http://backend.tager.ee/eastron1ph')
          .then(response => response.json())
          .then(data => {
            const firstData = data[0];
            setHetkeTarbimine(firstData.tarbimine);
            setSagedus(firstData.sagedus);
            setPinge(firstData.pinge);
            setKoguTarbimine(firstData.total);
          })
          .catch(err => console.error('Error fetching data:', err));

          fetch('http://backend.tager.ee/arvesti1')
          .then(response => response.json())
          .then(data => {
            const firstData = data[0];
            setHetkeTarbimine2(firstData.Phase1Watts);
          })
          .catch(err => console.error('Error fetching data:', err));

          
      };


      fetch('http://backend.tager.ee/elering')
      .then(response => response.json())
      .then(data => {
        const now = new Date(); 
        now.setMinutes(0, 0, 0); // reset minutes, seconds and milliseconds to 0
        data.forEach(item => {
          const dt = item["Ajatempel (UTC)\";\"Kuup�ev (Eesti aeg)\";\"NPS Eesti"].split(';');
          const date = new Date(parseInt(dt[0]) * 1000); // convert timestamp to date object
          if (date.getHours() === now.getHours() && date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear()) {
            const priceData = dt[2];
            let num = parseFloat(priceData.replace('"', ''));
            num = num / 10
            num = num * 1.2;
            let result = num.toFixed(2);
            console.log(result);
            
            setPrice(result);
            return;
          }
        });
      })
      .catch(err => console.error('Error fetching data:', err));

    fetchData();
    const intervalId = setInterval(fetchData, 3000); // 60000 ms = 60 seconds
    
    // Clean up function
    return () => clearInterval(intervalId);
  }, []);
    
    
  

  return (
    <Box m={{ xs: "5px", md: "20px" }}>
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box 
  display="grid"
  gridTemplateColumns={{ xs: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }}
  gridAutoRows="140px"
  gap="20px"
>
        
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
           <StatBox
           
        title={`${hetkeTarbimine}W`} // Use state variable here
  
        subtitle="Hetke tarbimine"
        progress="0.50"
         increase="50"
        />
       </Box>      
      <Box
  gridColumn="span 3"
  backgroundColor={colors.primary[400]}
  display="flex"
  flexDirection="column"  // Set the direction to column
  alignItems="center"
  justifyContent="center"
>

  <ReactApexChart options={sparklineOptions} series={sparklineOptions.series} type="line" height={90} />
      </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={`${pinge}V`} 
            subtitle="Pinge"
            progress="0.30"
            increase="+5%"

          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={`${koguTarbimine}Kwh`} 
            subtitle="Kogu tarbimine"
            progress="0.80"
            increase="+43%"
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="15px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
              
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Arvutilaua elektri tarbimine
                
              </Typography>
              <Typography
              
                variant="h2"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {`${koguTarbimine}Kwh`} 
                
              </Typography>
              
            </Box>
            
            <Box>
              
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                  
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="0px 0 0 0">
            <LineChart />
          </Box>
        </Box>


        {/* ROW 3 */}
        <Box
          gridColumn="span 1"
          gridRow="span 1"
          backgroundColor={colors.primary[400]}
          p="40px"
        >
          <Typography variant="h5" fontWeight="600">
           
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="45px"
          >
            
            <Typography
              variant="h3"
              color={colors.greenAccent[500]}
              sx={{ mt: "-55px" }}
            >
              Börsihind: {price} senti/kWh
            </Typography>
            <Typography>
</Typography>
          </Box>
        </Box>
        <Box
          gridColumn="span 8"
          gridRow="span 5"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box>

      </Box>
    </Box>
  );
};

export default Dashboard;
