import { tokens } from "../theme";
import axios from 'axios';


export const mockDataTeam = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
];

export const mockDataContacts = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    address: "0912 Won Street, Alabama, SY 10001",
    city: "New York",
    zipCode: "10001",
    registrarId: 123512,
  },
];

export const mockDataInvoices = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    cost: "21.24",
    phone: "(665)121-5454",
    date: "03/12/2022",
  },
];

export const mockTransactions = [
  {
    txId: "01e4dsa",
    user: "johndoe",
    date: "2021-09-01",
    cost: "43.95",
  },
];
export const mockBarData = []; // Initialize an empty array

fetch('http://backend.tager.ee/elering')
  .then(response => response.json())
  .then(data => {
    const numberData = [];

    data.forEach(item => {
      const keys = Object.keys(item);
      keys.forEach(key => {
        let itemValue = item[key];
        let parts = itemValue.split(';');
        let lastPart = parts[parts.length - 1];
        let number = lastPart.replace("'", "").replace('"', "").replace('}', '');
        numberData.push(parseFloat(number));
      });
    });

    console.log(numberData);
    
    const now = new Date();
    now.setMinutes(0, 0, 0); // Reset minutes, seconds, and milliseconds to 0

    for (let i = 0; i < 24; i++) {
      const timestamp = new Date();
      timestamp.setHours(i, 0, 0, 0);

      // Get the price (number) for the corresponding hour
      const roundedPrice = ((numberData[i] || 0) * 1.2) / 10;
const price = roundedPrice.toFixed(2);

      //const price = (numberData[i] || 0) * 1.2;


      let color;
      if (price < 5) {
        color = "hsl(120, 70%, 50%)"; // Green
      } else if (price >= 7 && price < 20) {
        color = "hsl(39, 100%, 50%)"; // Orange
      } else {
        color = "hsl(0, 100%, 50%)"; // Red
      }
      

      mockBarData.push({
        Month: timestamp.getHours() + ":00",
        
        "Tarbimine":price,
        "TarbimineColor": color,
      });
    }
  })
  .catch(err => {
    console.error('Error fetching data from Elering API:', err);
  });




export const mockPieData = [
  {
    id: "hack",
    label: "hack",
    value: 239,
    color: "hsl(104, 70%, 50%)",
  },
];

export const mockLineData = [
  {
    id: "Arvesti1",
    color: tokens("dark").greenAccent[500],
    data: [
      {
        x: "Jaanuar",
        y: 101,
      },

    ],
  },
];

export const mockGeographyData = [
  {
    id: "AFG",
    value: 520600,
  },

];
