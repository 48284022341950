import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { startOfMinute, startOfHour, addMinutes, addHours, addDays, addWeeks, differenceInMinutes, format, isAfter } from "date-fns";
import { Select, MenuItem, Button, Tooltip, Box } from "@mui/material";



const INTERVALS = {
  '1min': 1,
  '10min': 10,
  '1h': 60,
};

const RANGES = {
  '10min': () => ({ start: addMinutes(new Date(), -10), end: new Date() }),
  '30min': () => ({ start: addMinutes(new Date(), -30), end: new Date() }),
  '1h': () => ({ start: addHours(new Date(), -1), end: new Date() }),
  '1day': () => ({ start: addDays(new Date(), -1), end: new Date() }),
  '1week': () => ({ start: addWeeks(new Date(), -1), end: new Date() }),
};

const LineChart = ({
  isCustomLineColors = false,
  isDashboard = false,
  selectedValues = ["Tarbimine"],
}) => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      id: "basic-line",
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: 'white'
        }
      },
      axisBorder: {
        show: true,
        color: 'white'
      },
      axisTicks: {
        show: true,
        color: 'white'
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: 'white'
        }
      },
      axisBorder: {
        show: true,
        color: 'white'
      },
      axisTicks: {
        show: true,
        color: 'white'
      }
    },
    grid: {
      borderColor: '#f1f1f1'  // Optional: Adjust this if you also want to modify the grid line colors
    },
    tooltip: {
      theme: 'dark', // this will set the tooltip background to dark. If you want it to be light, use 'light'.
      style: {
        colors: ['#000'] // color of the text
      }
    }
  });
  const [interval, setInterval] = useState('1min'); // Default interval
  const [dateRange, setDateRange] = useState(RANGES['1h']()); // Default date range
  const [refreshData, setRefreshData] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const startDate = format(dateRange.start, "yyyy-MM-dd'T'HH:mm:ss'Z'");
      const endDate = format(dateRange.end, "yyyy-MM-dd'T'HH:mm:ss'Z'");
      console.log(`Fetching data from ${startDate} to ${endDate}`);

      const response = await fetch(`http://backend.tager.ee/arvesti1ajalugu?start=${startDate}&end=${endDate}`);
      const fetchedData = await response.json();

      // Filter data based on selected values and aggregate by interval
      let intervalData = {};
      fetchedData.forEach(entry => {
        let date = new Date(entry.Date);
        date.setHours(date.getHours() + 3); // adjust the time

        let roundedDate;
        if (INTERVALS[interval] < 60) {
          // Round down to nearest multiple of interval minutes
          let remainder = differenceInMinutes(date, startOfHour(date)) % INTERVALS[interval];
          roundedDate = addMinutes(startOfHour(date), differenceInMinutes(date, startOfHour(date)) - remainder);
        } else {
          // Round down to nearest hour
          roundedDate = startOfHour(date);
        }

        if (!intervalData[roundedDate]) {
          intervalData[roundedDate] = {};
          selectedValues.forEach(value => {
            intervalData[roundedDate][value] = [];
          });
        }

        selectedValues.forEach(value => {
          intervalData[roundedDate][value].push(entry[value]);
        });
      });

      // Calculate average for each interval
      let averagedData = Object.keys(intervalData).map(date => {
        let entry = { x: new Date(date).getTime() };
        selectedValues.forEach(value => {
          let total = intervalData[date][value].reduce((acc, val) => acc + val, 0);
          let average = total / intervalData[date][value].length;
          entry[value] = Math.round(average * 1000) / 1000; // rounds to 3 decimal places
        });
        return entry;
      });

      // Convert data to the format expected by ApexCharts
      const processedData = selectedValues.map((value) => ({
        name: value,
        data: averagedData.map((entry) => [entry.x, entry[value]]),
      }));

      setSeries(processedData);
    };

    if (refreshData) {
      fetchData();
      setRefreshData(false);
    }
  }, [selectedValues, interval, dateRange, refreshData]);

  const handleIntervalChange = (event) => {
    setInterval(event.target.value);
  };

  const handleRangeChange = (key) => {
    setDateRange(RANGES[key]());
    setRefreshData(true);
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between" marginBottom="1em">
        <Tooltip title="Interval" arrow>
          <Select
            value={interval}
            onChange={handleIntervalChange}
          >
            {Object.keys(INTERVALS).map((key) => (
              <MenuItem value={key} key={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
        
        <div>
          {Object.keys(RANGES).map((key) => (
            <Button 
              onClick={() => handleRangeChange(key)}
              variant={isAfter(dateRange.end, RANGES[key]().end) && !isAfter(dateRange.start, RANGES[key]().start) ? "contained" : "outlined"}
              key={key}
            >
              {key}
            </Button>
          ))}
        </div>
      </Box>

      <Button onClick={() => setRefreshData(true)}>Refresh</Button>
      <Chart
        options={options}
        series={series}
        type="line"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default LineChart;