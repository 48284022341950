import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

const Andmed = () => {
  const [arvestiData, setArvestiData] = useState(null);
  const [eastronData, setEastronData] = useState(null);

  const fetchArvestiData = () => {
    fetch("http://backend.tager.ee/arvesti1")
      .then((response) => response.json())
      .then((data) => setArvestiData(data[0]));
  };

  const fetchEastronData = () => {
    fetch("http://backend.tager.ee/eastron1ph")
      .then((response) => response.json())
      .then((data) => setEastronData(data[0]));
  };

  useEffect(() => {
    fetchArvestiData();
    fetchEastronData();

    const timer = setInterval(() => {
      fetchArvestiData();
      fetchEastronData();
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  if (!arvestiData || !eastronData) {
    return "Loading...";
  }

  const arvestid = [
    {
      name: "Boiler, Pesumasin, pumbad ja elutuba",
      watts: (arvestiData.Phase1Watts - eastronData.tarbimine).toFixed(2),
      voltage: arvestiData.Phase1Voltage,
      frequency: arvestiData.Frequency,
    },
    {
      name: "2. Korrus",
      watts: arvestiData.Phase2Watts,
      voltage: arvestiData.Phase2Voltage,
      frequency: arvestiData.Frequency,
    },
    {
      name: "Valgus",
      watts: arvestiData.Phase3Watts,
      voltage: arvestiData.Phase3Voltage,
      frequency: arvestiData.Frequency,
    },
    {
      name: "ArvutiLaud",
      watts: eastronData.tarbimine,
      voltage: eastronData.pinge,
      frequency: eastronData.sagedus,
      total: (eastronData.total).toFixed(2),
    },
    {
      name: "Arvesti 5",
      watts: "Placeholder 5",
      voltage: "Placeholder 5",
      frequency: "Placeholder 5",
    },
  ];

  return (
    <Box m="20px">
      <Typography variant="h4" align="center" mb={2}>
        NÄIDUD
      </Typography>
      <Box display="flex" justifyContent="center" flexWrap="wrap" gap={2}>
        {arvestid.map((arvesti, index) => (
          <Box
            key={index}
            width="300px"
            border={1}
            borderColor="grey.500"
            borderRadius={1}
            p={2}
          >
            <Typography variant="h6">{arvesti.name}</Typography>
            <Typography variant="body1">
              HetkeTarbimine: {arvesti.watts} W
            </Typography>
            <Typography variant="body1">
              Pinge: {arvesti.voltage} V
            </Typography>
            <Typography variant="body1">
              Sagedus: {arvesti.frequency} Hz
            </Typography>
            <Typography variant="body1">
              Tarbimine: {arvesti.total} Kwh
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Andmed;
